module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-images","id":"32658888-e97e-563f-9132-b45b813fd0fb","name":"gatsby-remark-images","version":"6.9.0","modulePath":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-mermaid","id":"7a3a0615-7e85-5c40-92c7-0b58aab29318","name":"gatsby-remark-mermaid","version":"2.1.0","modulePath":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-mermaid/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-obsidian","id":"f245288a-95f3-5228-9792-59b7184dc805","name":"gatsby-remark-obsidian","version":"0.5.0","modulePath":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden/node_modules/gatsby-remark-obsidian/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/martinstellar/Dropbox/OPS/1 Actual/0 Desk/Vaults/SFCDev/my-garden","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
